<template>
  <div class="center">
    <div class="company">
      <div class="name">{{ alldata.consumerName }}</div>
      <div class="tell">
        <i class="hy-icon-dianhua"></i> {{ alldata.consumerTelephone }}
      </div>
    </div>
    <div class="header_title">订单信息</div>
    <div class="order">
      <div class="left">
        <img src="@/assets/images/dingdan.jpg" />
      </div>
      <div class="table">
        <el-descriptions class="margin-top" :column="3" size="medium" border>
          <el-descriptions-item :span="3" label-class-name="my-label">
            <template slot="label"> 状态： </template>
            {{ otherOrderStatus[alldata.otherOrderStatus] }}
          </el-descriptions-item>
          <el-descriptions-item :span="3" label-class-name="my-label">
            <template slot="label"> 订单创建时间： </template>
            {{ alldata.createTime }}
          </el-descriptions-item>
          <el-descriptions-item
            v-if="alldata.receiveOrderTime"
            :span="3"
            label-class-name="my-label"
          >
            <template slot="label"> 服务商接单时间： </template>
            {{ alldata.receiveOrderTime }}
          </el-descriptions-item>
          <el-descriptions-item
            :span="3"
            v-if="alldata.payTime"
            label-class-name="my-label"
          >
            <template slot="label"> 付款时间： </template>
            {{ alldata.payTime }}
          </el-descriptions-item>
          <el-descriptions-item
            :span="3"
            v-if="alldata.consumerSureTime"
            label-class-name="my-label"
          >
            <template slot="label"> 下单方确认服务完成时间： </template>
            {{ alldata.consumerSureTime }}
          </el-descriptions-item>
          <el-descriptions-item
            :span="3"
            v-if="alldata.providerSureTime"
            label-class-name="my-label"
          >
            <template slot="label"> 服务商确认完成时间： </template>
            {{ alldata.providerSureTime }}
          </el-descriptions-item>
          <el-descriptions-item :span="3" label-class-name="my-label">
            <template slot="label"> 支付订单总金额(元)： </template>
            {{ alldata.orderPayAmount }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <div class="header_title">关联的服务信息</div>
    <div class="info">
      <div class="relation">
        <div class="left pointer"  @click="todetail">
          <div class="img">
            <img class="img" :src="alldata.mainGraph" />
          </div>
          <div class="every">
            <div class="first  f-hide"  style="width:400px">{{ alldata.name }}</div>
            <div class="secend">
              服务类别：{{ alldata.serviceType }} | 单价：{{
                alldata.goodsPrice + alldata.unit
              }}
            </div>
            <div class="thired">{{ alldata.createTime }}</div>
          </div>
        </div>
        <div class="right">
          <div class="tag">{{ alldata.onShelf ? "销售中" : "已下架" }}</div>
        </div>
      </div>
    </div>
    <div
      v-if="alldata.otherOrderStatus == 1 || alldata.otherOrderStatus == 4"
      @click="changestatus(4, alldata.serviceOrderId)"
      class="bottom pointer"
    >
      确认服务完成
    </div>
    <div
      v-if="alldata.otherOrderStatus == 0 || alldata.otherOrderStatus == 1"
      @click="toaplay(alldata)"
      class="bottom pointer"
    >
      去支付
    </div>
    <div
      v-if="alldata.otherOrderStatus == 0"
      @click="changestatus(5, alldata.serviceOrderId)"
      class="bottom pointer"
    >
      取消订单
    </div>
    <keyworder
      :isShow.sync="passWordIsShow"
      :balance="balance"
      ref="InputPasswordDialog"
      title="请输入交易密码"
      :row="alldata"
      @submit="submit"
    />
  </div>
</template>

<script>
import { detailServiceOrder, dealServiceOrder, applymenoy } from "@/api/home";
const { baseUrl } = require("@/config/index.js");
import md5 from "js-md5";
import { getAccountBalanceByParam } from "@/api/Accountopening";
export default {
  data() {
    return {
      balance:[],
      passWordIsShow: false,
      alldata: {
        creditStartTime:''
      },
      otherOrderStatus: [
        "待付款",
        "已付款",
        "已完成",
        "已取消",
        "对方已确认",
        "等待对方确认",
      ],
    };
  },
  mounted() {
    this.getdetail();
  },
  methods: {
    getbalance(row) {
      let config = {
        goodsId: row.serviceGoodId,
        searchType: 2,
      };
      getAccountBalanceByParam(config).then((res) => {
        if (res.code == 0) {
          this.balance = res.data;
          // this.centerDialogVisible = true;
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    toaplay(row) {
      if (!this.beforhandle('personalCenter:ptaxOrder:ongoing:topay')) return;
      this.getbalance(row)
      this.passWordIsShow = true;
    },
    submit(obj) {
      let _this=this
      applymenoy({
        serviceOrderId: this.alldata.serviceOrderId,
        ...obj,
      }).then((res) => {
        if (res.code == 0) {
          _this.getdetail();
          this.$message.success("支付成功");
        } else {
          this.$message.error(res.message);
        }
      });
    },
    changestatus(operateType, serviceOrderId) {
      if (!this.beforhandle(['','','','',['personalCenter:ptaxOrder:ongoing:cancel','personalCenter:pstorageOrder:ongoing:confirm'],['personalCenter:ptaxOrder:ongoing:confirm','personalCenter:pstorageOrder:ongoing:cancel']][operateType])) return;
      const status = [
        "",
        "确认接单",
        "确认拒绝接单",
        "确认服务完成",
        "确认服务完成",
        "确认取消订单"
      ];
      this.$confirm(status[operateType], "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "zzdd_confirm",
      })
        .then(() => {
          dealServiceOrder({ serviceOrderId, operateType }).then((res) => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getdetail();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    todetail() {
      // this.$router.push({
      //   path: "/myPublisheddetail",
      //   query: {
      //     id: this.alldata.serviceGoodId,
      //   },
      // });
      this.$router.push({
        path: "/associateddetail",
        query: {
          id: this.alldata.serviceGoodId,
          type: 'other'
        },
      });
    },
    getdetail() {
      detailServiceOrder(this.$route.query.id).then((res) => {
        if (res.code == 0) {
          this.alldata = {
            ...res.data.serviceOrderVO,
            mainGraph:
              baseUrl +
              "third/third/ossGetFile/" +
              res.data.serviceOrderVO.mainGraph,
            goodsPrice: res.data.serviceOrderVO.goodsPrice
              ? res.data.serviceOrderVO.goodsPrice
              : "面议",
            createTime: res.data.serviceOrderVO.createTime.replace("T", " "),
            finishTime: res.data.serviceOrderVO.finishTime
              ? res.data.serviceOrderVO.finishTime.replace("T", " ")
              : "",
            allprice: res.data.serviceOrderVO.goodsPrice
              ? (res.data.serviceOrderVO.num *
                  100 *
                  (res.data.serviceOrderVO.goodsPrice * 100)) /
                10000
              : "面议",
          }
          this.alldata.payTime = this.changedata(this.alldata.payTime)
          this.alldata.consumerSureTime = this.changedata(this.alldata.consumerSureTime)
          this.alldata.providerSureTime = this.changedata(this.alldata.providerSureTime)
        }
      });
    },
    changedata(value){
      if(value){
        return value.replace("T", ' ')
      }
    }
  },
};
</script>
<style lang="scss">
.zzdd_confirm {
  .el-message-box {
    width: 450px !important;
    height: 218px !important;
    background: #ffffff !important;
    border-radius: 10px !important;
  }
  .el-message-box__header {
    background: #f8f8f8;
  }
  .el-message-box__title {
    font-size: 16px;
    font-weight: 400;
    color: #666666;
  }
  .el-message-box__content {
    padding: 49px 119px;
    color: #606266;
    font-size: 14px;
  }
  .el-message-box__btns {
    .el-button--primary {
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
    }
  }
}
</style>
<style>
.my-labels {
  width: 284px;
  text-align: right !important;
}
.my-label {
  width: 218px;
  text-align: right !important;
}
.label_right {
  width: 205px;
  text-align: right !important;
}
</style>
<style lang="scss" scoped>
.center {
  width: 1195px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: -1px 0px 10px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  .bottom {
    width: 308px;
    margin: 0 auto;
    height: 34px;
    line-height: 34px;
    text-align: center;
    color: #ffffff;
    background: linear-gradient(90deg, #2079fb, #4cb2fc);
    border-radius: 4px;
    margin-bottom: 10px;
  }
  .info {
    .relation {
      display: flex;
      justify-content: space-between;
      padding: 17px;
      .left {
        display: flex;
        .img {
          width: 167px;
          height: 97px;
          border-radius: 2px;
        }
        .every {
          padding-left: 17px;
          .first {
            height: 20px;
            line-height: 20px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #40474e;
          }
          .secend {
            height: 15px;
            font-size: 14px;
            margin: 13px 0 31px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #40474e;
          }
          .thired {
            height: 9px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #787b80;
          }
        }
      }
      .right {
        .tag {
          width: 68px;
          height: 22px;
          text-align: center;
          line-height: 20px;
          color: #188827;
          border: 1px solid #188827;
          border-radius: 2px;
        }
      }
    }
  }
  .header_title {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #000000;
    padding-left: 15px;
    height: 45px;
    line-height: 45px;
    background: #fafafa;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
  }
  .company {
    padding: 23px 13px 20px;
    .name {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
    }
    .tell {
      margin-top: 15px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #666666;
    }
  }
  .order {
    display: flex;
    padding: 20px 0;
    .left {
      width: 267px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .table {
      width: 900px;
      padding-top: 14px;
    }
  }
}
</style>>